.meContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    align-items: center; /* Center align children horizontally */

    margin-top: 1rem;
}

.uiSection {
    background-color: var(--color-card);
    border-radius: 15px;
    width: 40rem;
    margin: 1rem auto;
    padding: 2rem 1rem;
    text-align: center; /* Center align text */
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center align children horizontally */

}

.colorCircle {
    margin-top: 2rem;
    width: 8rem;
    height: 8rem;
    border-radius: 50%; /* Use border-radius to make it round */
}

.contentSection {
    margin: 1rem;
}

.contentSection p {
    margin: .5rem;
}

@media (prefers-color-scheme: dark) {
    div {
        color: white;
    }

    .uiSection {
        background-color: var(--color-dark-card);
    }
}

@media (max-width: 600px) {
  .uiSection {
    width: 90%;
    margin: 1rem auto;
    padding: 1rem;
  }

  .colorCircle {
    width: 6rem;
    height: 6rem;
  }

  .contentSection {
    margin: 0.5rem;
  }
}
