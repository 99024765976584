.createBoardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.inputSection, .choiceSection, .reviewSection {
  width: 75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

input, textarea, select {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
}

.choiceSection div {
  cursor: pointer;
  padding: 1rem;
  background-color: #007bff;
  color: white;
  border-radius: 10px;
  text-align: center;
  width: 50%;
}

button {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  cursor: pointer;
}

/* Style for the editable board name box */
.boardNameBox {
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: var(--color-card);
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.boardNameIcon {
  width: 1rem;
  height: 1rem;
}

/* Container for public/private choice buttons in a row */
.choiceButtonsRow {
  display: flex;
  justify-content: space-around;
  width: 75vw;
  gap: 1rem;
  margin-top: 1rem;
}

/* Style for each choice button */
.choiceButton {
  border-radius: 15px;
border: 1px solid var(--color-stroke);
  color: white;
  text-align: center;
  flex: 1;
  cursor: pointer;
overflow: hidden;
}


/* New styling for public/invite-only cards */
.choiceCardTop {
  background-color: var(--color-card);
  padding: 1rem;
}

.choiceCardBottom {
  background-color: var(--color-card-secondary);
  padding: 1rem;
Border-top: 1px solid var(--color-stroke);
}

.choiceCardBottom h4 {
font-size: 97%;
}

.choiceCardBottom p {
font-size: 85%;
}


/* Suggestion input styling */
.suggestionInput {
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  text-align: center;
  border: none;
  outline: none;
  border-radius: 15px;
  background-color: var(--color-card);
}

/* Suggestion box styling */
.suggestionBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px 0;
  padding: 8px;
  background-color: var(--color-card);
  border-radius: 15px;
}

/* Remove button styling for suggestion boxes */
.removeButton {
  background: none;
  border: none;
  color: red;
  cursor: pointer;
  font-size: 1.2em;
}

/* Navigation buttons container */
.navigationButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
}

/* Next and Back button styling */
.navButton {
  border: none;
  border-radius: 15px;
  background-color: var(--color-card);
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}

@media (max-width: 400px) {
  .choiceButtonsRow {
    flex-direction: column;
    width: 100%;
  }
  
  .reviewRow {
    width: 100%;
    margin-bottom: 1rem;
  }
}


@media (prefers-color-scheme: dark) {
    
    .boardNameBox {
        background-color: var(--color-dark-card);
    }
    
    .choiceButton {
        background-color: var(--color-dark-card);
        border: 1px solid var(--color-dark-stroke);
    }
    
    .suggestionInput {
        background-color: var(--color-dark-card);
    }
    
    .suggestionBox {
        background-color: var(--color-dark-card);
    }
    
    .removeButton {
        color: var(--color-dark-neutral-secondary);
    }
    
    .navButton {
        background-color: var(--color-dark-card);
    }
    
    .choiceCardTop {
        background-color: var(--color-dark-card);
    }
    
    .choiceCardBottom {
        background-color: var(--color-dark-card-secondary);
        Border-top: 1px solid var(--color-dark-stroke);
        color: var(--color-neutral-secondary)

    }
    
    .choiceCardBottom h4 {
        color: var(--color-neutral-secondary)
    }
    
    .choiceCardBottom p {
        color: var(--color-neutral-secondary);
    }
    
    .createBoardContainer {
        background-color: var(--color-dark-background);
    }
    
    .inputSection, .choiceSection, .reviewSection {
        background-color: var(--color-dark-card);
    }
    
    input, textarea, select {
        background-color: var(--color-dark-card);
        color: white;
    }
    
    button {
        background-color: var(--color-dark-card);
        color: white;
    }
    
  
    .navigationButtons {
        background-color: var(--color-dark-card);
    }
    
  }

